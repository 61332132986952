<!--视频管理-->
<template>
    <div>
        <a-row class="searchBox">
            <a-space class="flex flex-wrap">
                <a-select ref="select" v-model:value="selectObject.grade_id" style="width: 150px" allowClear placeholder="年级" @change="filterGetList">
                    <a-select-option v-for="(item,idx) in seletArray.grade" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
                <a-select ref="select" v-model:value="selectObject.term_id" style="width: 150px" allowClear placeholder="学期" @change="filterGetList">
                    <a-select-option v-for="(item,idx) in seletArray.term" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
                <a-select ref="select" v-model:value="selectObject.subject_id" style="width: 150px" allowClear placeholder="科目" @change="filterGetList">
                    <a-select-option v-for="(item,idx) in seletArray.subject" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
                <a-select ref="select" v-model:value="selectObject.chapter_id" style="width: 200px" allowClear showSearch :filter-option="filterOption" placeholder="章节" @change="filterKnowList">
                    <a-select-option v-for="(item,idx) in seletArray.chapter" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
                <a-select ref="select" v-model:value="selectObject.knowledge_id" style="width: 200px" allowClear showSearch :filter-option="filterOption" placeholder="知识点" @change="handleSelectChange">
                    <a-select-option v-for="(item,idx) in seletArray.knowledge" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
                <a-button type="primary" @click="handleEdit()">
                    <PlusCircleOutlined />添加视频
                </a-button>
            </a-space>
        </a-row>
        <a-row class="tableBox">
            <a-table :dataSource="list" :columns="columns" rowKey="id" :pagination="false" :scroll="{x: 1000, y: 'calc(100vh - 390px)' }">
                <template #number="{text, record, index}">
                    <span>{{ (param.curr - 1) * param.row + index + 1 }}</span>
                </template>
                <template #cover="{text, record, index}">
                    <a-image :width="60" :src="domain+text" :fallback="domain+'/default.png'" style="border-radius:4px" />
                </template>
                <template #operation="{ record }">
                    <a-space>
                        <a-button type="primary" @click="handleEdit(record)">编辑</a-button>
                        <a-button type="primary" @click="handleDelete(record)">删除</a-button>
                    </a-space>
                </template>
            </a-table>
            <div class="flex flex-jc-fe Pageing">
                <TablePagination :curr="param.curr" :row="param.row" :count="count" @changeTableRequest="changeTableRequest" />
            </div>
        </a-row>

        <VideoEdit ref="VideoEditRef" cateId="1" :seletArray="seletArray" @success="getList"></VideoEdit>
    </div>
</template>
<script>
import { defineComponent, reactive, toRefs, ref, getCurrentInstance, onMounted } from 'vue';
import TablePagination from '@/components/TablePagination';
import useDelete from '@/hooks/useDelete.js';
import { PlusCircleOutlined } from '@ant-design/icons-vue';
import VideoEdit from '@/components/VideoEdit';
import { useRoute } from 'vue-router'

export default defineComponent({
    components: {
        TablePagination,
        PlusCircleOutlined,
        VideoEdit
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const { useDeleteFun } = useDelete();
        const VideoEditRef = ref();
        const route = useRoute();
        onMounted(() => {
            if (route.query.knowledge_id) {
                Object.assign(initData.selectObject, {knowledge_id:Number(route.query.knowledge_id),grade_id: Number(route.query.grade_id), subject_id: Number(route.query.subject_id), term_id: Number(route.query.term_id), chapter_id: Number(route.query.chapter_id) });
            };
            TypeClass();
            getList();
            ChapterList();//章节列表
            KnowledgeList();
        });

        let initData = reactive({
            list: [],
            columns: [
                { title: '序号', dataIndex: 'number', width: 80, slots: { customRender: 'number' } },
                { title: '视频封面', dataIndex: 'cover', slots: { customRender: 'cover' } },
                { title: '视频标题', dataIndex: 'name' },
                { title: '知识点', dataIndex: 'Knowledge.name' },
                { title: '观看量', dataIndex: 'view_count_sum' },
                { title: '点赞量', dataIndex: 'like_count_sum' },
                { title: '录入时间', dataIndex: 'create_time' },
                { title: '操作', fixed: 'right', width: 180, slots: { customRender: 'operation' } },
            ],
            param: {
                curr: 1,
                row: 10,
                cate_id: 1
            },
            selectObject: {},
            seletArray: {},
            count: 0,
            pages: 1,
            domain: proxy.domain
        });
        const TypeClass = () => {
            proxy.http.post('/admin.chapter/config').then(response => {
                initData.seletArray = response;
            })
        };
        //获取章节
        const ChapterList = () => {
            initData.seletArray.chapter = [];
            initData.seletArray.knowledge = [];
            if (!(initData.selectObject.grade_id && initData.selectObject.subject_id && initData.selectObject.term_id)) return;
            proxy.http.post('/admin.chapter/indexAll', initData.selectObject).then(response => {
                initData.seletArray['chapter'] = response;
            })
        }

        //获取视频
        const KnowledgeList = () => {
            initData.seletArray.knowledge = [];
            if (!(initData.selectObject.grade_id && initData.selectObject.subject_id && initData.selectObject.term_id && initData.selectObject.chapter_id)) return;
            proxy.http.post('/admin.knowledge/indexAll', initData.selectObject).then(response => {
                initData.seletArray['knowledge'] = response;
            })
        }

        //视频列表
        const getList = () => {
            Object.assign(initData.param, initData.selectObject);
            proxy.http.post('/admin.video/index', initData.param).then(response => {
                initData.list = response.list;
                initData.count = response.count;
                initData.pages = response.pages;
            })
        };

        // 类型年级科目筛选
        const filterGetList = () => {
            initData.selectObject.chapter_id = undefined;
            initData.selectObject.knowledge_id = undefined;
            handleSelectChange();
            ChapterList();
        };

        // 章节下拉筛选
        const filterKnowList = () => {
            initData.selectObject.knowledge_id = undefined;
            handleSelectChange();
            KnowledgeList();
        }

        //下拉筛选过滤
        const filterOption = (input, option) => {
            return (
                option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        };
        const changeTableRequest = (event, value) => {
            if (event == 'curr') {
                initData.param.curr = value;
            } else {
                initData.param.curr = 1;
                initData.param.row = value;
            }
            getList();
        };

        //新建弹框
        const handleEdit = (row) => {
            let { chapter_id, grade_id, subject_id, name, term_id, knowledge_id, like_count_set, view_count_set, url, cover, content } = row ? row : initData.selectObject;
            VideoEditRef.value.title = row ? '编辑视频' : '添加视频';
            VideoEditRef.value.form = row ? {
                id: row.id,
                name,
                cate_id: 1,
                grade_id,
                term_id,
                subject_id,
                chapter_id,
                knowledge_id,
                like_count_set,
                view_count_set,
                url,
                cover,
                content
            } : {
                cate_id: 1,
                grade_id,
                term_id,
                subject_id,
                chapter_id,
                knowledge_id,
            };
            VideoEditRef.value.ChapterList();
            VideoEditRef.value.knowledgeList();
            VideoEditRef.value.EducationList();
            VideoEditRef.value.getToken();
            VideoEditRef.value.handleDrawer(true);
        };

        //删除
        const handleDelete = (row) => {
            useDeleteFun('/admin.video/delete', [row.id]).then(() => {
                getList();
            })
        };
        //下拉筛选
        const handleSelectChange = () => {
            initData.param.curr = 1;
            initData.list = [];
            getList();
        };

        return {
            ...toRefs(initData),
            VideoEditRef,
            ChapterList,//章节列表
            KnowledgeList,//视频列表
            getList,
            TypeClass,
            filterGetList,
            filterKnowList,
            changeTableRequest,
            handleEdit,
            handleDelete,
            handleSelectChange,
            filterOption
        }
    }
})
</script>
